import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Register from './pages/Register';
import Help from './pages/Help';
import Pdf from './pages/Pdf';
import Premium from './pages/Premium';
import Main from './pages/Main';

// This will handle login/registration

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Replace with a proper loading spinner or component
  }

  return (
    <Router>
      <Header user={user}/>
      <main className="content">
        <Routes>
          {user ? (
            // Authenticated routes
            <>
            <Route path="/" element= {<Main/>}/>
               <Route path="/pdf" element={<Pdf/>} />
               <Route path="/help" element={<Help/>} />
               <Route path="/premium" element={<Premium/>} />
               
            
            </>
          ) : (
            // Non-authenticated routes
            <>
               <Route path="/" element={<Home />} />
              
              <Route path="/register" element={<Register />} />
              <Route path="/help" element={<Help/>} />
             
            </>
          )}
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
