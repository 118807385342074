import React from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './Help.css';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
function Help() {
  const navigate = useNavigate(); // Hook to navigate
const handlecp = async()=>{
  try {
    const app = getApp();
    const functions = getFunctions(app, 'us-central1');
    const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    
    const { data } = await functionRef({
      returnUrl: window.location.origin,
    });

    window.location.assign(data.url);

  } catch (error) {
    console.error('Error creating portal link:', error);
  }
}
  const handleSignOut = () => {
    const confirmSignOut = window.confirm("Are you sure you want to sign out?");
    
    if (confirmSignOut) {
      auth.signOut().then(() => {
        navigate("/"); // Redirect to home after signing out
      }).catch((error) => {
        console.error('Sign Out Error', error);
      });
    }
  };

  return (
    <div className="help-container">
      <h1>Welcome to the Help Dashboard</h1>
      <div className="button-container">
        <button className="manage-payments-btn" onClick={handlecp}>Manage Payments</button>
        <button onClick={handleSignOut} className="sign-out-btn">Sign Out</button>
      </div>
      <div className="common-questions">
        <h2>Common Questions</h2>
        <div className="question">
          <strong>Q: What if my lecture slides are not a PDF?</strong>
          <p>You can take a PPTX file, open the project, go to File, export, and save it as a PDF.</p>
        </div>
        <div className="question">
          <strong>Q: How many PDFs can I combine?</strong>
          <p>You can combine up to 25 PDFs.</p>
        </div>
        <div className="question">
          <strong>Q: When is the Assignment Nerd feature coming out?</strong>
          <p>The Assignment Nerd is coming out in November 2024 and will be able to complete all assignments without direct proofs from sources but will complete an estimated 90% of all assignments.</p>
        </div>
      </div>
    </div>
  );
}

export default Help;
