import { getAuth, onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Main = () => {
    const navigate = useNavigate();
    const [isVerified, setIsVerified] = useState(null);
    const [message, setMessage] = useState('');
    const [a, setA] = useState(null)

    const handleSendEmail = () => {
   
  
      if (a) {
        sendEmailVerification(a)
          .then(() => {
            console.log('Verification email sent.');
            alert('Verification email sent. Please check your inbox.');
          })
          .catch((error) => {
            console.error('Error sending verification email:', error);
            alert('Verification email sent. Please check your inbox.');
          });
      } else {
        alert('No user is currently signed in.');
      }
    };
  
    useEffect(() => {
        const auth = getAuth()
        setA(auth.currentUser)
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          if (user.emailVerified) {
            navigate('/pdf'); // Redirect to /pdf if the user is verified
          } else {
            setIsVerified(false);
            setMessage('Your email is not verified. Click the button below once to send the email. It make a minute to recieve the email and you may need to refresh the page after verifying.');
            // Send email verification if not verified
            sendEmailVerification(user).catch((error) => {
              console.error("Error sending email verification:", error);
            });
          }
        } else {
          setIsVerified(false);
          setMessage('You are not logged in. Please log in to access this page.');
        }
      });
  
      // Cleanup subscription
      return () => unsubscribe();
    }, [navigate]);
  
    return (
      <div style={styles.container}>
        {isVerified === false && 
        <div>
        <p>{message}</p>
        <button onClick={handleSendEmail}>
      Send Verification Email
    </button>
        </div>
        }
      </div>
    );
  };
  
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '500px',
      padding: '200px 20px', // Vertical padding of 200px, horizontal of 20px
      margin: '0 auto', // Center horizontally
      textAlign: 'center', 
      marginBottom:"400px"// Center text
    },
  };
  
  export default Main;