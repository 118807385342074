import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Make sure to import your Firebase auth
import './Header.css';
import logo from "../headimg.png";

const Header = ({ user }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate(); // Hook to navigate

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

   

    return (
        <>
            <header className="header">
                <div className="logo">
                    <img 
                        src={logo} 
                        alt="Logo" 
                        style={{ maxWidth: '250px', width: '100%', height: 'auto' }} 
                    />
                </div>
                <div className="menu-icon" onClick={toggleSidebar}>
                    &#9776;
                </div>
                <nav className="nav-menu">
                    <Link to="/">Home</Link>
                    {user ? (
                        <>
                           
                            <Link to="/help">Help</Link>
                            <Link to="/premium" className="premium-link">Upgrade to Premium</Link>
                        </>
                    ) : (
                        <>
                            <Link to="/register">Sign In</Link>
                            <Link to="/register">Register</Link>
                            <Link to="/help">Help</Link>
                        </>
                    )}
                </nav>
            </header>
            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <button className="close-btn" onClick={toggleSidebar}>
                    &times;
                </button>
                <Link to="/" onClick={toggleSidebar}>Home</Link>
                <Link to="/register" onClick={toggleSidebar}>Sign In</Link>
                <Link to="/register" onClick={toggleSidebar}>Register</Link>
                <Link to="/premium" onClick={toggleSidebar}>Upgrade to Premium</Link>
                <Link to="/help" onClick={toggleSidebar}>Help</Link>
            </div>
            <div className={`backdrop ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}></div>
        </>
    );
};

export default Header;
