import React, { useState } from 'react';
import './Register.css';
// Adjust the path based on your project structure
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore';

const Register = () => {
  const navigate = useNavigate(); 
  const [activeTab, setActiveTab] = useState('register');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsChecked, setTermsChecked] = useState(false); // State for checkbox
  const [signinEmail, setSigninEmail] = useState(''); // State for sign in email
  const [signinPassword, setSigninPassword] = useState(''); // State for sign in password

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const validateRegisterForm = async (event) => {
    event.preventDefault(); // Prevent the form from submitting immediately

    // Validation rules for Register
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex pattern
    
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (password.length <= 8) {
      alert("Password must be more than 8 characters.");
      return;
    }
    if (!termsChecked) {
      alert("You must agree to the Terms and Conditions.");
      return;
    }

    try {
     await createUserWithEmailAndPassword(auth, email,  password)
     .then( async (userCredential) => {
    
      const userid = userCredential.user.uid

      try {
        const docRef = doc(db, "users", userid)
        await setDoc(docRef,{
          email: email.toLowerCase(),
          credits:10,
        }, { merge: true });
        console.log("Document written with ID: ", docRef.id);
        //set modal to appear here and send the link to the email
        //modal goes down when link is
      } catch (e) {
        console.error("Error adding document: ", e);
      }})
     
    } catch (error) {
      alert(`Error during registration: ${error.message}`);
    }
    navigate("/")
  };

  const validateSigninForm = async (event) => {
    event.preventDefault(); // Prevent the form from submitting immediately

    // Validation rules for Sign In
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex pattern
    if (!emailPattern.test(signinEmail)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (signinPassword.length === 0) {
      alert("Password is required.");
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, signinEmail, signinPassword);
      alert("Sign In successful!");
      navigate("/")
      // Redirect or perform further actions after successful sign in
    } catch (error) {
      alert(`Error during sign in: ${error.message}`);
    }
  };

  return (
    <div className="auth-container">
      <div className="tab-navigation">
        <button
          onClick={() => handleTabChange('register')}
          className={`tab-button ${activeTab === 'register' ? 'active' : ''}`}
        >
          Register
        </button>
        <button
          onClick={() => handleTabChange('signin')}
          className={`tab-button ${activeTab === 'signin' ? 'active' : ''}`}
        >
          Sign In
        </button>
      </div>

      {activeTab === 'register' ? (
        <div>
          <h2 className="auth-title">Register</h2>
          <form onSubmit={validateRegisterForm}>
          
            <input
              type="email"
              placeholder="Email"
              className="input-field"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="checkbox-container">
              <input 
                type="checkbox" 
                checked={termsChecked}
                onChange={() => setTermsChecked(!termsChecked)}
                required 
              />
              <label className="checkbox-label">I agree to the Terms and Conditions</label>
            </div>
            <button type="submit" className="submit-button">Register</button>
          </form>
        </div>
      ) : (
        <div>
          <h2 className="auth-title">Sign In</h2>
          <form onSubmit={validateSigninForm}>
            <input
              type="email"
              placeholder="Email"
              className="input-field"
              value={signinEmail}
              onChange={(e) => setSigninEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              className="input-field"
              value={signinPassword}
              onChange={(e) => setSigninPassword(e.target.value)}
              required
            />
            <a href="#" className="reset-password-link" onClick={() =>{
                 if (!email) {
                  alert('Please provide a valid email.');
                  return;
                }
                const auth = getAuth();
                sendPasswordResetEmail(auth, email)
                .then(() => {
                 alert("Email sent")
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  // ..
                });
            }}>
              Reset Password
            </a>
            <button type="submit" className="submit-button">Sign In</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Register;

