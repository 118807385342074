import React, { useState } from 'react';
import './Premium.css';
import { db } from '../firebase';
import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const Premium = () => {
  const [uid, setuid] = useState(null)
  const handleClick = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
    
        const uid = user.uid;
        setuid(uid);
     
     
      
       
        const checkoutSessionsRef = collection(db, 'users', uid, 'checkout_sessions');
        
        const docRef = await addDoc(checkoutSessionsRef, {
          price: 'price_1Q8T78H7Fq7eMgGK8uY7RAAt',
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });
    
        // Wait for the CheckoutSession to get attached by the Firebase extension
        onSnapshot(docRef, (snap) => {
          const { error, url } = snap.data();
          if (error) {
            alert(`An error occurred: ${error.message}`);
            // Inspect Cloud Function logs for more details
          } else if (url) {
            // Redirect to Stripe Checkout
            window.location.assign(url);
          }
        });
    
      } catch (error) {
        console.error('Error creating checkout session:', error);
      }
  };

  return (
    <div className="premium-container">
      <h1 className="premium-title">      Are you ready to save time and get better results? </h1>

      <p className="premium-benefits">
        Imagine having more time to do the things you love while still acing your courses. With our tool, you can:
        <ul>
          <li>Generate smart notes instantly</li>
          <li>Stay organized effortlessly</li>
          <li>Never miss an important detail again</li>
          <li>Combine all lectures for the ultimate cheat sheet</li>
          <li>Utiilize our assignment nerd to kickstart your projects</li>
        </ul>
        It's time to take control of your learning. Are you in?
      </p>
      <button className="premium-button" onClick={handleClick}>
        Join Now
      </button>
    </div>
  );
};

export default Premium;

