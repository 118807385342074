import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import './Combine.css'; // Import your CSS file for styling

const Combine = () => {
  const [pdfFiles, setPdfFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setPdfFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleDeleteFile = (index) => {
    setPdfFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const mergePdfs = async () => {
    try {
      const mergedPdf = await PDFDocument.create();
      for (const pdfFile of pdfFiles) {
        const pdfBytes = await pdfFile.arrayBuffer();
        const pdf = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      const mergedPdfBytes = await mergedPdf.save();
      const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error merging PDFs:', error);
    }
  };

  return (
    <div className="combine-container">
 
      <input
        type="file"
        accept=".pdf"
        onChange={handleFileChange}
        multiple
        className="file-input"
      />
      <button 
        className="merge-button"
        onClick={mergePdfs}
        disabled={pdfFiles.length === 0} // Disable if no files are selected
      >
        Merge PDFs
      </button>
      {pdfFiles.length > 0 && (
        <ul className="file-list">
          {pdfFiles.map((file, index) => (
            <li key={index} className="file-item">
              {file.name}
              <button 
                className="delete-button"
                onClick={() => handleDeleteFile(index)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Combine;

