import React, { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import axios from 'axios';
import Combine from '../Combine';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, doc, getDoc, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
 // Ensure you import Firestore methods


const Pdf =  () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [apiResponse, setApiResponse] = useState(''); // State for API response
  const [showTextArea, setShowTextArea] = useState(false); // State to control text area visibility
  const [isLoading, setIsLoading] = useState(false); // State for loading
  const [activeTab, setActiveTab] = useState('aiNoteGenerator'); // State for active tab
const [subber, setsubber] = useState(null)
  const [uid, setUid] = useState(null);
  const [credits, setCredits] = useState(null)
  const [credtext, setcredtext] = useState("")

  useEffect(() => {
    const auth = getAuth();
    
    // Subscribe to auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, set the UID
        setUid(user.uid);
        console.log(user.uid);

        // Get the user's credits from Firestore
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setCredits(userData.credits);
          setcredtext("Credits Remaining: ");
        } else {
          console.log('No such user document!');
        }

        // Query active or trialing subscriptions
        const subscriptionsRef = collection(db, 'users', user.uid, 'subscriptions');
        const activeSubscriptionsQuery = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

        onSnapshot(activeSubscriptionsQuery, (snapshot) => {
          if (!snapshot.empty) {
            const doc = snapshot.docs[0]; // Expecting one active or trialing subscription
            console.log(doc.id, ' => ', doc.data());
            setsubber(true);
          } else {
            console.log('No active or trialing subscriptions found.');
            setsubber(false);
          }
        });
      } else {
        // User is signed out, clear the UID and credits
        setUid(null);
        setCredits(0);
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [uid]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
    } else {
      alert('Please upload a valid PDF file.');
    }
  };
  const subCredits = async () => {
    try {
      // Subtract one from the local credits state
      const newCredits = credits - 1; // This can go negative if credits are 0
      setCredits(newCredits);
  
      // Update the user's document in Firestore
      const docRef = doc(db, 'users', uid); // Assuming uid is already set
      await updateDoc(docRef, {
        credits: newCredits,
      });
  
      console.log("Credits updated successfully to:", newCredits);
    } catch (error) {
      console.error("Error updating credits:", error);
    }
  };
  
  const generateText = async () => {
    if(!subber && credits<=0){
alert("Out of credits, upgrade to premium now!")
    }else{

    
    
    if (!pdfFile) {
      alert('Please upload a PDF file.');
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = function () {
      const typedArray = new Uint8Array(this.result);

      pdfjsLib.getDocument(typedArray).promise.then((pdf) => {
        const numPages = pdf.numPages;
        let extractedText = '';

        const extractPageText = async (pageNum) => {
          const page = await pdf.getPage(pageNum);
          const textContent = await page.getTextContent();
          const textItems = textContent.items.map((item) => item.str).join(' ');

          extractedText += textItems + '\n';
          if (pageNum < numPages) {
            extractPageText(pageNum + 1);
          } else {
            if (extractedText.trim().length === 0) {
              alert('Please upload a PDF with text.');
            } else {
              await callServerAPI(extractedText); // Call the API to summarize text
            }
          }
        };

        extractPageText(1);
      }).catch(err => {
        alert('Error extracting text from PDF: ' + err.message);
      });
    };

    fileReader.readAsArrayBuffer(pdfFile);
    subCredits();
  }};

  const callServerAPI = async (text) => {
    try {
      setIsLoading(true);
// Update credits
      const res = await axios.post('https://us-central1-backendlp.cloudfunctions.net/chat', { text });
      setApiResponse(res.data); // Set the response from the API
      setShowTextArea(true); // Show text area after receiving response
    } catch (error) {
      console.error('Error during API call:', error);
      alert('Error: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiResponse)
      .then(() => {
        alert('Text copied to clipboard!');
      })
      .catch(err => {
        alert('Failed to copy text: ' + err);
      });
  };

  return (
    <div style={styles.container}>
      {subber ?
      <>
    
      </>:
      <>
      <p>{credtext} {credits}</p>
      </>}
      <div style={styles.tabContainer}>
        <div
          style={{ ...styles.tab, ...(activeTab === 'aiNoteGenerator' ? styles.activeTab : {}) }}
          onClick={() => setActiveTab('aiNoteGenerator')}
        >
          AI Note Generator
        </div>
        <div
          style={{ ...styles.tab, ...(activeTab === 'pdfCombiner' ? styles.activeTab : {}) }}
          onClick={() => setActiveTab('pdfCombiner')}
        >
          PDF Combiner
        </div>
        {/* <div
          style={{ ...styles.tab, ...(activeTab === 'assignmentNerd' ? styles.activeTab : {}) }}
          onClick={() => setActiveTab('assignmentNerd')}
        >
          Assignment Nerd
        </div> */}
      </div>

      {activeTab === 'aiNoteGenerator' && (
        <>
        <p>This is the AI notetaker, upload your lecture slides as a PDF and get amazing detailed notes instantly.</p>
          <input type="file" accept="application/pdf" onChange={handleFileChange} style={styles.fileInput} />
          <button onClick={generateText} style={styles.button} disabled={isLoading}>
            {isLoading ? 'Generating...' : 'Generate'}
          </button>
          
          {showTextArea && apiResponse && ( // Only show text area if there is a response
            <>
              <button onClick={copyToClipboard} style={styles.copyButton}>Copy All</button>
              <textarea 
                value={apiResponse} 
                readOnly 
                style={styles.textArea} 
                placeholder="API response will appear here..." 
              />
            </>
          )}
        </>
      )}

      {activeTab === 'pdfCombiner' && (
        <div style={styles.blankTab}>
         <Combine/>
        </div>
      )}

      {/* Uncomment when you want to add functionality to the Assignment Nerd tab */}
      {/* {activeTab === 'assignmentNerd' && (
        <div style={styles.blankTab}>
          <p>This tab is currently under construction.</p>
        </div>
      )} */}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    margin:"auto",
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '50px',
    backgroundColor: '#f9f9f9',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    maxWidth:"700px",
    paddingBottom:"500px"
  },
  tabContainer: {
    display: 'flex',
    width: '100%', // Ensure tabs cover full width
  },
  tab: {
    flex: 1, // Each tab takes equal width
    padding: '15px',
    cursor: 'pointer',
    backgroundColor: '#e0e0e0',
    textAlign: 'center',
    borderBottom: '2px solid transparent', // Border for active tab
    transition: 'background-color 0.3s',
  },
  activeTab: {
    backgroundColor: 'blue',
    color: 'white',
    borderBottom: '2px solid blue', // Active tab border
  },
  fileInput: {
    margin: '20px 0',
    border: '2px solid blue',
    borderRadius: '4px',
    padding: '10px',
    width: '100%',
  },
  button: {
    padding: '15px 30px',
    fontSize: '18px',
    cursor: 'pointer',
    backgroundColor: 'blue',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s, transform 0.2s',
  },
  textArea: {
    marginTop: '20px',
    width: '100%',
    height: '300px',
    fontSize: '16px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    resize: 'none',
    backgroundColor: '#fff',
  },
  copyButton: {
    marginTop: '10px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s, transform 0.2s',
  },
  blankTab: {
    marginTop: '20px',
    fontSize: '16px',
    color: '#555',
  },
};

export default Pdf;
