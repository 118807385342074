import React from 'react';
import './Home.css';
import onlinePresenceImage from './p1.jpg'; // Update with the correct path
import { useNavigate } from 'react-router-dom';
import { FaStar } from 'react-icons/fa'; 
const Home = () => {
  const reviews = [
    {
      name: "Kyle",
      university: "Brock University",
      review: "As a student at Brock, I can’t believe how much time I save with LecturePal. It’s like having my own personal assistant in class!",
    },
    {
      name: "Emily",
      university: "University of Toronto",
      review: "LecturePal is a game changer! I’ve improved my grades significantly since I started using it. Highly recommended!",
    },
    {
      name: "Daniel",
      university: "McGill University",
      review: "I was skeptical at first, but LecturePal has truly transformed how I study. The notes are organized and easy to follow!",
    },
    {
      name: "Aisha",
      university: "University of Alberta",
      review: "Thanks to LecturePal, I can focus more on understanding the material instead of frantically writing down everything. It’s fantastic!",
    },
    {
      name: "Liam",
      university: "Simon Fraser University",
      review: "I love the clarity of the notes generated by LecturePal. It’s like having a study buddy who knows exactly what to highlight. Game changer for sure!",
    },
    {
      name: "Sophie",
      university: "University of British Columbia",
      review: "LecturePal has saved me countless hours. My grades have improved, and I feel more confident going into exams. Thank you!",
    },
    {
      name: "Noah",
      university: "Western University",
      review: "I didn’t think I needed LecturePal, but now I can’t imagine studying without it! It’s made learning so much more efficient.",
    },
    {
      name: "Mia",
      university: "Dalhousie University",
      review: "I’m so impressed with how LecturePal captures lectures. My study sessions are so much more effective now. Definitely worth it!",
    },
  ];
  const navigate = useNavigate();
  return (
    <div className="home">
      {/* Title and Image Section */}
      <section className="title-section">
        <div className="title-content">
          <h1>LecturePal- AI Notetaker</h1>
          <p>Come join our community of students working smarter not harder.</p>
          <div className="see-work">
        <button className="see-work-button"  onClick={() => navigate('/register')}>Join Now</button>
      </div>
        </div>

      </section>
<div style={{padding:"10px"}}>


      {/* What We Do Section */}
      <section className="what-we-do">
        <div className="service-box">
          <h2>AI Notetaker</h2>
          <p>Upload your lecture PDF. Generate amazing notes in seconds. That is all there is to it. Our students have saved on average 10 hours a week and have noticed a significant bump in their marks. Now you can actually listen to what your prof is saying instead of scrambling to keep up.</p>
        </div>
        <div className="service-box">
          <h2>PDF Combiner</h2>
          <p>Get 100% on every take home test and online quiz from now on. Upload all your lecture slides and click combine and have the ultimate cheat sheet or exam study guide. And guess what, its free. </p>
        
        </div>
      </section>

      {/* See Our Work Button */}
   

      {/* Contact Form */}
      {/* <section className="contact-form">
        <h2>Contact Us</h2>
        <form>
          <div className="form-group">
            <label>Name</label>
            <input type="text" name="name" required />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input type="email" name="email" required />
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea name="message" required></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </section> */}
       <div style={{
      padding: '20px',
      backgroundColor: '#f8f8f8',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      border: '10px solid #007BFF', 
    }}>
     
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '20px',
      }}>
        {reviews.map((review, index) => (
          <div key={index} style={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            padding: '15px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
            }}>
              {[...Array(5)].map((_, starIndex) => (
                <FaStar key={starIndex} color="#FFD700" />
              ))}
            </div>
            <p style={{
              fontStyle: 'italic',
              margin: '10px 0',
            }}>{`"${review.review}"`}</p>
            <p style={{
              fontWeight: 'bold',
              color: '#555',
            }}>{`${review.name}, ${review.university} Student`}</p>
          </div>
        ))}
      </div>
      </div>
    </div>
    </div>
  );
};

export default Home;

