// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDYtv8pW2zrRZsNnQE2MARrIR2lqDjNwFw",
    authDomain: "lectpal-faaa4.firebaseapp.com",
    projectId: "lectpal-faaa4",
    storageBucket: "lectpal-faaa4.appspot.com",
    messagingSenderId: "637093304352",
    appId: "1:637093304352:web:bb672636f961bb27918445"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app); // Initialize Firestore
